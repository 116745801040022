<script setup>
import textHelpers from "../functions/text";
</script>

<template>
  <MetaInfo :title="meta.title" :description="meta.description"></MetaInfo>

  <navigation
    announcementText="Let AI bring your email content to life."
    :announcementLink="{
      url: '/features/ai-content-assistant',
      text: 'Try our new AI Content Assistant',
    }"
    announcementIcon="ai-outline-dark"
  ></navigation>

  <PageHeader
    title="The easy to use<br/> marketing platform"
    subtitle-text="Communicate with ease and grow your business. Create. Automate. Engage. Analyze. "
  >
    <PageHeaderRating
      button-text="Get started"
      rating-text=""
    ></PageHeaderRating>
  </PageHeader>

  <div class="homepage-hero__image-container">
    <img
      src="../assets/images/home/home-dashboard-screenshot-2.png"
      alt="Email Editor Screenshot"
      class="email-editor-screenshot animate-in"
    />
  </div>

  <div class="feature-container">
    <section class="feature-showcase">
      <div class="feature-showcase__header">
        <span class="feature-label feature-label--blue">Create</span>
        <h2 class="section-title">Email never looked so good.</h2>
        <h2 class="section-subtitle">Build campaigns that convert.</h2>
      </div>

      <div class="feature-showcase__preview feature-showcase--top">
        <img
          src="../assets/images/home/email-editor-screenshot.png"
          alt="Email Editor Screenshot"
          class="feature-showcase__image"
          width="800"
          height="600"
        />
        <div class="feature-showcase__info text-left">
          <h4>Campaigns</h4>
          <p>
            Design, personalize, and automate email campaigns that drive
            engagement. Create stunning emails, segment your audience, and
            tailor messages to specific customer journeys. With A/B testing,
            scheduling, and performance tracking, our tool ensures every
            campaign delivers results.
          </p>
          <a href="/campaigns" class="btn btn--primary btn--sm mt-8">
            Learn more
          </a>
        </div>
      </div>
    </section>

    <section class="feature-showcase-wrapper">
      <div class="feature-showcase-flex">
        <section class="feature-showcase">
          <div class="feature-showcase__preview">
            <img
              src="../assets/images/home/signup-form.png"
              alt="Email Editor Screenshot"
              class="feature-showcase__image"
              width="800"
              height="600"
            />
            <div class="feature-showcase__info text-left">
              <h4>Signup Forms</h4>
              <p style="color: var(--color-gray-900)">
                Capture new subscribers with customizable forms that can be
                hosted or embedded on your website. Build your audience and
                collect leads seamlessly.
              </p>
            </div>
          </div>
        </section>

        <section class="feature-showcase">
          <div class="feature-showcase__preview dark">
            <img
              src="../assets/images/home/ai-content.png"
              alt="Email Editor Screenshot"
              class="feature-showcase__image"
              width="800"
              height="600"
            />
            <div class="feature-showcase__info dark text-left">
              <h4>AI Content Assistant</h4>
              <p style="color: var(--color-gray-600)">
                Draft subject lines and email copy in seconds. Just share your
                thoughts and it'll generate creative suggestions that fit your
                style and message perfectly.
              </p>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>

  <div class="feature-container">
    <section class="feature-showcase-wrapper">
      <div class="feature-showcase__header text-center">
        <span class="feature-label feature-label--blue">Automate</span>
        <h2 class="section-title">Work smarter, not harder.</h2>
        <h2 class="section-subtitle">Unlock the power of automation.</h2>
      </div>
      <div class="feature-showcase-flex">
        <section class="feature-showcase">
          <div class="feature-showcase__preview feature-showcase--top">
            <img
              src="../assets/images/home/automations-screenshot.png"
              alt="Automations Screenshot"
              class="feature-showcase__image"
              width="800"
              height="600"
            />
            <div class="feature-showcase__info text-left">
              <h4>Automations</h4>
              <p>
                Set up triggers, actions, and rules to automate your campaigns
                with our no-code visual automations builder.
              </p>
            </div>
          </div>
        </section>
        <section class="feature-showcase">
          <div class="feature-showcase__preview">
            <img
              src="../assets/images/home/transactional-email.png"
              alt="Transactional Email Screenshot"
              class="feature-showcase__image"
              width="800"
              height="600"
            />
            <div class="feature-showcase__info text-left">
              <h4>Transactional Email</h4>
              <p style="color: var(--color-gray-900)">
                Automatically send order confirmations, receipts, welcome emails
                and more. Set up once and we’ll handle the rest.
              </p>
            </div>
          </div>
        </section>

        <section class="feature-showcase">
          <div class="feature-showcase__preview">
            <img
              src="../assets/images/home/brand-analyzer.png"
              alt="Brand Analyzer Screenshot"
              class="feature-showcase__image"
              width="800"
              height="600"
            />
            <div class="feature-showcase__info text-left">
              <h4>Brand Analyzer</h4>
              <p style="color: var(--color-gray-900)">
                Use our Brand Analyzer tool to quickly and easily create
                templates that match the look and feel of your brand.
              </p>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>

  <div class="feature-container">
    <section class="feature-showcase-wrapper">
      <div class="feature-showcase__header text-center">
        <span class="feature-label feature-label--blue">Analyze</span>
        <h2 class="section-title">Goodbye guesswork, hello answers.</h2>
        <h2 class="section-subtitle">Insights that you can use.</h2>
      </div>
      <div class="feature-showcase-flex">
        <section class="feature-showcase">
          <div class="feature-showcase__preview">
            <img
              src="../assets/images/home/analytics-screenshot.png"
              alt="Analytics Screenshot"
              class="feature-showcase__image"
              width="800"
              height="600"
            />
            <div class="feature-showcase__info text-left">
              <h4>Analytics</h4>
              <p style="color: var(--color-gray-900)">
                Obtain clear insights into how your emails and campaigns are
                performing. Track opens, clicks, and more - allowing you to make
                make smarter decisions and improve results.
              </p>
            </div>
          </div>
        </section>

        <section class="feature-showcase">
          <div class="feature-showcase__preview">
            <img
              src="../assets/images/home/website-tracking.png"
              alt="Website Tracking Screenshot"
              class="feature-showcase__image"
              width="800"
              height="600"
            />
            <div class="feature-showcase__info text-left">
              <h4>Website Tracking</h4>
              <p style="color: var(--color-gray-900)">
                Transpond's website tracking helps you to see what recipients do
                after clicking a link in one of your communications, and then
                set up automations, alerts and follow up communications.
              </p>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>

  <div class="feature-container">
    <section class="feature-showcase-wrapper">
      <div class="feature-showcase__header text-center">
        <span class="feature-label feature-label--blue">Engage</span>
        <h2 class="section-title">
          Centralize conversations with inbox, live chat and omni-channel
          campaigns.
        </h2>
      </div>

      <section class="feature-showcase">
        <div class="feature-showcase__preview feature-showcase--top">
          <img
            src="../assets/images/home/conversations-screenshot.png"
            alt="Conversations Screenshot"
            class="feature-showcase__image"
            width="800"
            height="600"
          />
          <div class="feature-showcase__info text-left">
            <h4>Conversations</h4>
            <p>
              A centralized inbox for efficiently managing and triaging
              enquiries, support tickets, and customer interactions. It allows
              your team to collaborate seamlessly, prioritize tasks, and resolve
              issues faster, ensuring no message is missed.
            </p>
          </div>
        </div>
      </section>

      <div class="feature-showcase-flex">
        <section class="feature-showcase">
          <div class="feature-showcase__preview">
            <img
              src="../assets/images/home/social-campaigns.png"
              alt="Social Campaigns Screenshot"
              class="feature-showcase__image"
              width="800"
              height="600"
            />
            <div class="feature-showcase__info text-left">
              <h4>Social Campaigns</h4>
              <p style="color: var(--color-gray-900)">
                Schedule and manage omni-channel marketing campaigns across
                multiple platforms. Plan, create, and automate posts for
                Facebook, Instagram, Twitter, and more - all from one place.
              </p>
            </div>
          </div>
        </section>

        <section class="feature-showcase">
          <div class="feature-showcase__preview">
            <img
              src="../assets/images/home/live-chat.png"
              alt="Live Chat Screenshot"
              class="feature-showcase__image"
              width="800"
              height="600"
            />
            <div class="feature-showcase__info text-left">
              <h4>Live Chat</h4>
              <p style="color: var(--color-gray-900)">
                Instant, personalized support embedded directly on your website.
                Engage with customers in real time, answer their questions and
                resolve issues more efficiently.
              </p>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>

  <div class="integrations-section">
    <div class="integrations-container">
      <section class="integrations-showcase">
        <div class="integrations-showcase__header">
          <h2 class="section-title" style="font-size: 36px; font-weight: bold">
            Connect to the tools you love
          </h2>
          <a href="/integrations" class="btn btn--primary btn--sm">
            View all integrations
          </a>
        </div>

        <div class="integrations-grid">
          <div
            class="integration-card"
            v-for="integration in integrations"
            :key="integration.name"
          >
            <router-link :to="'/integrations/' + integration.slug">
              <img
                :src="integration.icon"
                :alt="integration.name + ' icon'"
                class="integration-icon"
              />
              <h3>{{ integration.name }}</h3>
              <p>{{ integration.description }}</p>
            </router-link>
          </div>
        </div>
      </section>
    </div>
  </div>

  <!-- New section -->
  <section class="cta-section">
    <div class="cta-container">
      <h2 class="cta-title">The all-in-one marketing platform</h2>
      <div class="cta-buttons">
        <a href="/pricing" class="btn btn--primary">Get started free</a>
      </div>
    </div>
  </section>

  <site-footer></site-footer>
</template>

<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/Main";
import PageHeader from "@/components/Header/PageHeader";
import PageHeaderRating from "@/components/Header/PageHeaderRating";
import SiteFooter from "@/components/SiteFooter";

import axios from "axios";
import siteType from "../functions/siteType";

var isUkSite = siteType.lookup();
isUkSite = true;

export default {
  name: "HomePage",
  components: {
    MetaInfo,
    Navigation,
    PageHeader,
    PageHeaderRating,
    SiteFooter,
  },
  data() {
    return {
      activeTestimonial: 1,
      signupFormFirstName: "",
      signupFormEmail: "",
      signupFormError: "",
      themeData: [],
      isUkSite: isUkSite,
      appsData: [],
      homeHeroImage: "home/home-dashboard-screenshot",
      meta: {
        title: "Marketing tools for business - Transpond",
        description:
          "Try Transpond - Easy to use  marketing suite. Create standout communications to send to your customers and grow your business.",
      },
      integrations: [
        {
          name: "Capsule",
          slug: "capsule-email-marketing/8",
          icon: require("../assets/images/home/capsule-icon.svg"),
          description:
            "Automatically have contacts synced in real time between platforms.",
        },
        {
          name: "Zapier",
          slug: "zapier-email-marketing/41",
          icon: require("../assets/images/home/zapier-icon.svg"),
          description:
            "Connect to Zapier and start syncing data between thousands of other apps.",
        },
        {
          name: "Stripe",
          slug: "stripe-email-marketing/15",
          icon: require("../assets/images/home/stripe-icon.svg"),
          description:
            "Connect with Stripe, sync Customers and setup automated emails.",
        },
        {
          name: "Slack",
          slug: "slack-email-marketing/53",
          icon: require("../assets/images/home/slack-icon.svg"),
          description:
            "Automatically send messages to Slack from Campaigns and Automations.",
        },
      ],
    };
  },

  created() {
    axios
      .get("https://api.transpond.io/api/templates?limit=3")
      .then((response) => {
        this.themeData = response.data.Templates;
        return axios.get("https://api.transpond.io/api/applications?limit=10");
      })
      .then((response) => {
        this.appsData = response.data.Applications;
      });
  },
  methods: {
    clickTestimonial(theID) {
      this.activeTestimonial = Number(theID);
    },
    openWindow: function (link, self) {
      window.open(link, self ? "_self" : "_blank");
    },
    addSubscriber() {
      var headers = {
        headers: {
          "x-access-token":
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJBY2NvdW50TWFzdGVySWQiOjYsIlNpdGVNYXN0ZXJJZCI6MSwiQXBpS2V5SWQiOjEwNSwiaWF0IjoxNTM0NDI3MTU2fQ.JMkZ02FQ__dLHyVvM0XNWCY3k28ge7FNCkCS11AbCq8",
        },
      };
      var postData = {
        emailAddress: this.signupFormEmail,
        blackListed: false,
        customFields: {
          61: this.signupFormFirstName,
        },
        active: true,
      };

      axios
        .post(
          "https://api.transpond.io/group/2446/subscriber",
          postData,
          headers
        )
        .then(() => {
          this.signupFormError = `<div class="text-primary">Awesome! Thanks for subscribing.. We'll send you an email soon! :D </div>`;
        })
        .catch((error) => {
          var errorText = error.response.data.error;
          if (errorText.toLowerCase() == "subscriber already bound to group")
            errorText = `<div class="text-primary">You're already on our list, there's no prizes for signing up twice</div>`;

          this.signupFormError = errorText
            ? '<div class="text-secondary">' + errorText + "</div>"
            : '<div class="text-secondary">Oh no! There was a problem, try again...</div>';
        });
    },
    clickApp(theApp) {
      this.$router.push({
        path:
          "/integrations/" +
          textHelpers.stringToSlug(theApp.appName + " email marketing") +
          "/" +
          theApp.id,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.feature-container {
  border-radius: 80px 80px 0px 0px;
  background: linear-gradient(180deg, #fff 0%, #fafaf9 100%);
  margin-top: 60px;
  margin-bottom: 50px;
}

.feature-showcase--top {
  margin-bottom: 40px;
}

.feature-showcase {
  max-width: 1225px;
  margin: 0 auto;
  text-align: center;
  position: relative;
}

.feature-showcase__preview {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.03);
  overflow: hidden;
  border-radius: 16px;
  position: relative;
  padding: 8px;
  transition: box-shadow 0.3s ease-in-out;
}

.feature-showcase__preview:hover {
  box-shadow: 0 25px 30px -5px rgb(0 0 0 / 0.15),
    0 10px 15px -6px rgb(0 0 0 / 0.03);
}

.feature-showcase__preview::before {
  content: "";
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  border: 1px solid #eeeeed;
  border-radius: 10px;
  pointer-events: none;
  z-index: 1;
}

.feature-showcase__preview img {
  transition: transform 0.3s ease-in-out;
  position: relative;
  z-index: 0;
  width: 100%;
  height: auto; /* Change from 100% to auto */
  object-fit: cover;
  border-radius: 12px 12px 0 0; /* Remove bottom radius */
  display: block; /* Add this line */
}

.feature-showcase__preview:hover img {
  transform: scale(1.05);
}

.feature-showcase__preview.dark {
  background-color: #000000;
  box-shadow: 0px 8px 17px 0px rgba(255, 255, 255, 0.1);
}

.feature-showcase__preview.dark::before {
  border: 1px solid #4d4d4d;
}

.feature-showcase__preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.feature-showcase__info {
  padding: 32px;
  margin: 0 auto;
  background: white;
  border-radius: 0 0 12px 12px;
  margin-top: -4px; /* Add this line to remove the gap */
}

.feature-showcase__info h4 {
  font-size: 20px;
  margin-bottom: 1rem; /* Add gap between header and paragraph */
}

.feature-showcase__info.dark {
  background-color: #000000;
  color: #ffffff;
}

.feature-showcase__info.dark h4 {
  color: #ffffff;
}

.feature-showcase__info.dark p {
  color: #e0e0e0;
}

.feature-showcase__info.dark .btn--primary {
  background-color: #fff;
  color: #000;
}

.feature-showcase__info.dark .btn--primary:hover {
  background-color: #000;
}

.feature-label {
  font-weight: 500;
  font-size: 1.125rem;
  margin-bottom: 1rem;
  display: inline-block;
  color: var(--color-indigo-600);
}

.feature-showcase__header {
  font-size: 50px;
  font-weight: 900;
  margin-top: 80px;
  padding: 80px 0 80px;
}

.animate-in {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.feature-showcase-wrapper {
  max-width: 1225px;
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
}

.feature-showcase-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.feature-showcase-flex .feature-showcase {
  flex: 1;
  display: inline-block;
}

@media (max-width: 768px) {
  .feature-showcase-flex {
    flex-direction: column;
    gap: 30px;
  }

  .feature-showcase-flex .feature-showcase {
    flex-basis: 100%;
    max-width: 100%;
  }

  .feature-showcase {
    flex-basis: 100%;
    max-width: 100%;
    padding: 0 20px; /* Add padding for mobile */
  }

  .feature-showcase__header {
    font-size: 32px; /* Smaller font size for mobile */
    padding: 40px 0; /* Reduced padding for mobile */
  }

  .feature-container {
    margin-top: 30px; /* Reduced top margin for mobile */
    margin-bottom: 60px; /* Reduced bottom margin for mobile */
  }

  .feature-showcase-wrapper {
    margin-bottom: 30px; /* Reduced bottom margin for mobile */
  }

  .feature-showcase-flex {
    gap: 30px; /* Increased gap for better spacing on mobile */
  }

  .section-title {
    font-size: 28px; /* Smaller font size for section titles on mobile */
  }

  .section-subtitle {
    font-size: 20px; /* Smaller font size for section subtitles on mobile */
  }
}
.integrations-section {
  background-color: #fff;
  padding: 80px 0;
}

.integrations-container {
  max-width: 1225px;
  margin: 60px auto;
  padding: 0 20px;
}

.integrations-showcase__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.integrations-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.integration-card {
  background-color: #fff;
  border-radius: 16px;
  padding: 32px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.03);
  border: none; /* Remove the border as we're using box-shadow for the outline */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.integration-card::before {
  content: "";
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  border: 1px solid #eeeeed;
  border-radius: 10px;
  pointer-events: none;
}

.integration-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.03);
}

.integration-icon {
  width: 48px;
  height: 48px;
  margin-bottom: 40px;
}

.integration-card h3 {
  color: #000;
  font-size: 18px;
  margin-bottom: 10px;
}

.integration-card p {
  font-size: 14px;
  color: #666;
}

.integration-card a {
  text-decoration: none;
}

@media (max-width: 768px) {
  .integrations-showcase__header {
    flex-direction: column;
    align-items: flex-start;
  }

  .integrations-showcase__header .btn {
    margin-top: 20px;
  }
}

.cta-section {
  background-color: #fafaf9; /* Light grey background */
  padding: 150px 0;
  text-align: center;
}

.cta-container {
  max-width: 1225px;
  margin: 0 auto;
  padding: 0 20px;
}

.cta-title {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 40px;
  color: #333; /* Darker text color for contrast */
}

@media (max-width: 768px) {
  .cta-title {
    font-size: 36px;
  }
}

.homepage-hero__image-container {
  margin: 20px auto;
}

.email-editor-screenshot {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .homepage-hero__image-container {
    margin: 20px auto; /* Increased margin for mobile view */
  }
}
</style>
